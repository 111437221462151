<template>
  <v-row
    v-if="(items.length > 0 && loaded) || !loaded"
  >
    <v-col>
      <h3>{{ $t("welcome.travelReqRecent") }}</h3>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="items.length === 0"
        mobile-breakpoint="800"
        hide-default-footer
        @click:row="view"
        class="row-pointer"
      >
        <template v-slot:item.departureDate="{ item }">
          {{ item.departureDate | dateFormat }}
        </template>
        <template v-slot:item.returnDate="{ item }">
          {{ item.returnDate | dateFormat }}
        </template>
        <template v-slot:item.status="{ item }">
          <StatusChip
            :statusId="item.statusId"
            :status="item.status"
          ></StatusChip>
        </template>
        <template v-slot:item.createdDate="{ item }">
          {{ item.createdDate | formatDate }}
        </template>
      </v-data-table>
      <div class="text-center">
        <v-btn to="/archive/travel" small>{{ $t("welcome.viewAll") }}</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import StatusChip from "@/components/StatusChip"

export default {
  data() {
    return {
      loaded: false,
      items: [],
      headers: [
        {
          text: this.$t("travelReq.table.travelNumber"),
          value: "travelId"
        },
        {
          text: this.$t("travelReq.table.departureDate"),
          value: "departureDate"
        },
        {
          text: this.$t("travelReq.table.returnDate"),
          value: "returnDate"
        },
        {
          text: this.$t("travelReq.table.status"),
          value: "status"
        },
        {
          text: this.$t("travelReq.table.manager"),
          value: "authorizeManagerFullName"
        },
        {
          text: this.$t("travelReq.table.createdDate"),
          value: "createdDate"
        }
      ]
    }
  },
  components: {
    StatusChip
  },
  methods: {
    view(travelReq) {
      this.$router.push({
        path: `/archive/travel/${travelReq.travelId}`
      })
    }
  },
  created() {
    this.$store
      .dispatch("travel/getArchive", { pageSize: 3 })
      .then((response) => {
        this.items = response.data
        this.loaded = true
      })
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
