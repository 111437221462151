<template>
  <div></div>
</template>

<script>
import * as signalR from "@microsoft/signalr"
import { mapState } from "vuex"
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(["syncConnection", "userInfo"])
  },
  async created() {
    this.syncConnection.on("ReceiveMessage", (user, message) => {
      console.log(`${user} says ${message}`)
    })
    this.syncConnection.on("UpdatePermissions", async () => {
      await this.$store.dispatch("getPermissions")
    })
    await this.start()
  },
  methods: {
    async start() {
      if (this.syncConnection.state == signalR.HubConnectionState.Disconnected) {
        var _this = this
        this.syncConnection
          .start()
          .then(function () {
            // _this.syncConnection
            //   .invoke("SendMessage", "Hello World")
            //   .catch(function (err) {
            //     return console.error(err.toString())
            //   })

            // setTimeout(() => {
            //   _this.syncConnection
            //     .invoke("SendPrivateMessage", "Darryl_Wu@Kingston.com", "Hello World")
            //     .catch(function (err) {
            //       return console.error(err.toString())
            //     })
            // }, 1000)
          })
          .catch(async (err) => {
            return console.error(err.toString())
          })
        // var _this = this
        // this.syncConnection
        //   .start()
        //   .then(function () {
        //     _this.syncConnection
        //       .invoke("GroupMe", _this.userInfo.employeeID)
        //       // .invoke("GroupMe", _this.userInfo.email)
        //       .catch(function (err) {
        //         return console.log(err)
        //       })

        //     // _this.joinNotifications()
        //   })
        //   .catch(async function (err) {
        //     await _this.retryConnection()
        //     return console.log(err)
        //   })
      }
    }
  }
}
</script>
