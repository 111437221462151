export default {
  draft: 100,
  submitted: 110,
  managerApproved: 200,
  // managerRejected: 205,
  generalAffairsApproved: 210,
  // generalAffairsRejected: 215,
  travelCoordinatorProcessed: 300,
  // travelCoordinatorRejected: 305,
  generalAffairsProcessed: 310,
  // generalAffairsHandlerRejected: 315,
  rejected: 400,
  completed: 500,
  archived: 600
}
