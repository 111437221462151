export default {
  mis: 100,
  admin: 200,
  manager: 300,
  siteManager: 301,
  generalAffairsManager: 310,
  travelCoordinator: 400,
  generalAffairsHandler: 410,
  accountsPayable: 500,
  ukReport: 510,
  needToKnow: 601
}
