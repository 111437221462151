import Vue from "vue"
import fvBusinessPurpose from "@/data/fvAccommodation/businessPurpose"
import fvHotels from "@/data/fvAccommodation/hotel"
import fvRoomTypes from "@/data/fvAccommodation/roomType"

Vue.mixin({
  data() {
    return { fvHotels, fvRoomTypes }
  },
  computed: {
    fvBusinessPurposes() {
      return Object.getOwnPropertyNames(fvBusinessPurpose)
    },
  }
})