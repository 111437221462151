export default [
  {
    hotelId: 1,
    hotelName: "Hilton Hotel, Costa Mesa"
  },
  {
    hotelId: 2,
    hotelName: "Avenue of the Arts, Costa Mesa"
  }
]
