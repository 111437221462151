<template>
  <v-row v-if="(items.length > 0 && loaded) || !loaded">
    <v-col>
      <h3>{{ $t("welcome.transportationReqRecent") }}</h3>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="items.length === 0"
        mobile-breakpoint="800"
        hide-default-footer
        @click:row="view"
        class="row-pointer"
      >
        <template v-slot:item.travelDate="{ item }">
          {{ item.travelDate | dateFormat }}
        </template>
        <template v-slot:item.transportTypeId="{ item }">
          <span v-if="item.transportTypeId === transportationType.shuttle">{{
            $t("transportation.shuttle.shuttle")
          }}</span>
          <span v-if="item.transportTypeId === transportationType.taxi">{{
            $t("transportation.taxi.taxi")
          }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <StatusChip
            :statusId="item.statusId"
            :status="item.status"
          ></StatusChip>
        </template>
        <template v-slot:item.createdDate="{ item }">
          {{ item.createdDate | formatDate }}
        </template>
      </v-data-table>
      <div class="text-center">
        <v-btn to="/archive/transportation" small>{{
          $t("welcome.viewAll")
        }}</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import StatusChip from "@/components/StatusChip"

export default {
  data() {
    return {
      loaded: false,
      items: [],
      headers: [
        {
          text: this.$t("transportation.table.transportNumber"),
          value: "transportationId"
        },
        {
          text: this.$t("transportation.table.travelDate"),
          value: "travelDate"
        },
        {
          text: this.$t("transportation.table.transportType"),
          value: "transportTypeId"
        },
        {
          text: this.$t("transportation.table.status"),
          value: "status"
        },
        {
          text: this.$t("transportation.table.manager"),
          value: "authorizeManagerFullName"
        },
        {
          text: this.$t("transportation.table.createdDate"),
          value: "createdDate"
        }
      ]
    }
  },
  components: {
    StatusChip
  },
  methods: {
    view(transportReq) {
      this.$router.push({
        path: `/archive/transportation/${transportReq.transportationId}`
      })
    }
  },
  created() {
    this.$store
      .dispatch("transportation/getArchive", { pageSize: 3 })
      .then((response) => {
        this.items = response.data
        this.loaded = true
      })
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
