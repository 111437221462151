import axios from "axios"
import statuses from "@/data/statuses"

function initialState() {
  return {
    authorizeCount: 0,
    approveCount: 0,
    processCount: 0
  }
}

const getters = {
  searchCount(state) {
    return state.approveCount + state.processCount
  }
}

const actions = {
  async addFVAccommodationReq({ commit }, data) {
    return axios.post("FVAccommodation/new", data)
  },
  async updateFVAccommodationReq({ commit }, data) {
    return axios.post("FVAccommodation/update", data)
  },
  async getFVAccommodationReq({ commit }, data) {
    return axios.get("FVAccommodation", { params: data })
  },
  async getArchive({ commit }, data) {
    return axios.get(`FVAccommodation/archive`, { params: data })
  },
  async searchFVAccommodationReq({ commit }, data) {
    return axios.get("FVAccommodation/search", { params: data })
  },
  async searchFVAccommodationReqByManager({ commit }, data) {
    return axios.get("FVAccommodation/authorize", { params: data })
  },
  async getAuthorizeCount({ commit }, data) {
    return axios.get("FVAccommodation/authorize-count").then((response) => {
      commit("setAuthorizeCount", response.data)
      return response
    })
  },
  async getApproveCount({ commit }, data) {
    return axios.get("FVAccommodation/approve-count").then((response) => {
      commit("setApproveCount", response.data)
      return response
    })
  },
  async getProcessCount({ commit }, data) {
    return axios.get("FVAccommodation/process-count").then((response) => {
      commit("setProcessCount", response.data)
      return response
    })
  },
  async approveFVAccommodationReq({ commit }, data) {
    return axios.post("FVAccommodation/approve", data)
  },
  async rejectFVAccommodationReq({ commit }, data) {
    return axios.post("FVAccommodation/reject", data)
  },
  async gaApproveFVAccommodationReq({ commit }, data) {
    return axios.post("FVAccommodation/ga-approve", data)
  },
  async gaRejectFVAccommodationReq({ commit }, data) {
    return axios.post("FVAccommodation/ga-reject", data)
  },
  async processFVAccommodationReq({ commit }, data) {
    return axios.post("FVAccommodation/process", data)
  },
  async rejectProcessFVAccommodationReq({ commit }, data) {
    return axios.post("FVAccommodation/reject-process", data)
  },
  async managerCommentFVAccommodationReq({ commit }, data) {
    return axios.post("FVAccommodation/comment-manager", data)
  },
  async gaManagerCommentFVAccommodationReq({ commit }, data) {
    return axios.post("FVAccommodation/comment-ga-manager", data)
  },
  async gaHandlerCommentFVAccommodationReq({ commit }, data) {
    return axios.post("FVAccommodation/comment-ga", data)
  }
}

const mutations = {
  setAuthorizeCount(state, count) {
    state.authorizeCount = count
  },
  setApproveCount(state, count) {
    state.approveCount = count
  },
  setProcessCount(state, count) {
    state.processCount = count
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
