export default [
  {
    roomTypeId: 1,
    roomType: "One Bed, Non Smoking"
  },
  {
    roomTypeId: 2,
    roomType: "Two Beds, Non Smoking"
  }
]
