import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        primary: "#C8102E",
        secondary: "#2263B2",
        accent: "#93D5FF",
        background: "#20262D",
        navigation: "#000"
      },
      light: {
        primary: "#C8102E",
        secondary: "#2263B2",
        accent: "#93D5FF",
        background: "#EAEEF1",
        navigation: "#F7F9FA"
      }
    }
  }
})
