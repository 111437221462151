<template>
  <div data-public>
    <v-speed-dial v-model="fab" right bottom fixed v-if="region && envText !== ''">
      <template v-slot:activator>
        <v-btn v-model="fab" color="secondary" fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-web </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        small
        :color="domain === lang.domain ? 'secondary' : 'primary'"
        v-for="lang in regions.slice().reverse()"
        :key="lang.region"
        @click="setDomain(lang.domain)"
      >
        {{ lang.domain }}
      </v-btn>
    </v-speed-dial>
    <v-dialog v-model="dialogRegion" width="400" overlay-opacity=".9" persistent>
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("region.regionCheck") }}
        </v-card-title>

        <v-card-text>
          {{ $t("region.detectedRegion") }} <strong>{{ newDomain }}</strong
          >.<br />
          {{ $t("region.switchRegion") }} <strong>{{ region }}</strong> >>
          <strong>{{ newDomain }}</strong
          >?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogRegion = false">{{ $t("region.no") }}</v-btn>
          <v-btn color="primary" @click="acceptnewDomain()">
            {{ $t("region.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "Region",

  data: () => ({
    fab: null,
    newDomain: null,
    dialogRegion: false
  }),
  computed: {
    ...mapState(["userInfo", "region", "domain"]),
    isAuthenticated() {
      return this.authState.isAuthenticated
    },
    regions() {
      return this.activeDomains
    }
  },
  watch: {
    async userInfo(val) {
      if (val) {
        await this.getUserRegion()
      }
    },
    region(val) {
      this.updateRegion()
    }
  },
  methods: {
    setDomain(domain) {
      this.$store.commit("setDomain", domain)
    },
    updateRegion() {
      console.log("Update Region", this.region)
      this.$root.$i18n.locale = this.regions.find(
        (x) => x.region == this.region
      ).language
    },
    getUserRegion() {
      this.$store
        .dispatch("employee/searchEmployee", { email: this.userInfo.email })
        .then((response) => {
          if (response.data.domain != this.domain) {
            this.newDomain = response.data.domain
            this.dialogRegion = true
          }
        })
    },
    acceptnewDomain() {
      this.dialogRegion = false
      this.setDomain(this.newDomain)
    }
  },
  created() {
    if (this.isAuthenticated && this.userInfo) {
      this.getUserRegion()
    }
    this.updateRegion()
  }
}
</script>
