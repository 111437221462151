<template>
  <div>
    <welcome />
  </div>
</template>

<script>
import Welcome from "../components/Welcome";

export default {
  name: "HomeView",

  components: {
    Welcome
  },
};
</script>
