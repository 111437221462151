import { setInteractionMode, extend } from 'vee-validate';
import { required, required_if, email, max, regex, digits, numeric } from 'vee-validate/dist/rules';

setInteractionMode('lazy');

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits',
})

extend('numeric', {
  ...numeric,
  message: '{_field_} may only contain numeric characters',
})

extend('required', {
  ...required,
  message: '{_field_} is required',
})

extend('required_if', {
  ...required_if,
  message: '{_field_} is required',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})