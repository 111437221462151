<template>
  <v-snackbar
    v-model="snackbar.open"
    :timeout="snackbar.timeout"
    top
    :color="snackbar.color"
  >
    {{ snackbar.message }}
    <!-- <v-progress-linear v-model="percentage" color="white"> </v-progress-linear> -->
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="snackbar.open = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      starTime: 0,
      percentage: 0
    }
  },
  computed: {
    ...mapState(["snackbar"])
  },
  watch: {
    snackbar(val) {
      this.startTime = new Date()
      if (val.timeout === undefined) val.timeout = 10000
      if (val.color === undefined) val.color = "info"
      // this.counter()
    }
  },
  methods: {
    counter() {
      this.percentage =
        (this.snackbar.timeout - 100 - (new Date() - this.startTime)) /
        (this.snackbar.timeout / 100)
      if (this.percentage > 0) {
        setTimeout(() => {
          this.counter()
        }, 50)
      }
    }
  },
  created() {}
}
</script>
