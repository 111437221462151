import Vue from "vue";
import moment from "moment";

Vue.filter("dateFormat", function(value, format) {
  if (value) {
    if (format) {
      return moment(String(value)).format(format);
    } else {
      return moment(String(value)).format("MM/DD/YYYY");
    }
  }
});

Vue.filter("timeFormat", function(value, format) {
  if (value) {
    if (format) {
      return moment(value).format(format);
    } else {
      return moment(value).format("h:mm A");
    }
  }
});

Vue.filter("timespanFormat", function(value, format) {
  if (value) {
    if (format) {
      return moment(value, ["hh:mm:ss"]).format(format);
    } else {
      return moment(value, ["hh:mm:ss"]).format("h:mm A");
    }
  }
});

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY hh:mma");
  }
});

Vue.filter("truncateText", function(value, length, ellipses) {
  if (value && length) {
    if (value.length > length) {
      return value.substring(0, length) + (ellipses === false ? "" : "...");
    }
  }
  return value;
});

Vue.filter("redacted", function(value) {
  if (value && value.toLowerCase() === "redacted redacted") {
    return "REDACTED"
  }
  return value;
});
