import Vue from "vue"
import App from "./App.vue"
// import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import "./filters"
import "./mixins"
import vuetify from "./plugins/vuetify"
import i18n from "./i18n"
import { ValidationProvider, ValidationObserver, localize } from "vee-validate"
import en from "vee-validate/dist/locale/en.json"
import zh from "vee-validate/dist/locale/zh_TW.json"
import "./validations"

import RequiredAsterisk from "@/components/util/RequiredAsterisk"

import LogRocket from "logrocket"

if (process.env.VUE_APP_ENV !== "LOCAL") {
  console.log("Enabling Log Rocket")
  LogRocket.init("do8otp/travel-req", {
    dom: {
      inputSanitizer: true,
      textSanitizer: true
    },
    network: {
      isEnabled: true
    }
  })
} else {
  console.log("Disabling Log Rocket")
}

Vue.config.productionTip = false

Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

Vue.component("RequiredAsterisk", RequiredAsterisk)

localize({
  en,
  zh
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app")
