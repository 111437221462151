import axios from "axios"

function initialState() {
  return {}
}

const getters = {}

const actions = {
  async listAttachments({ commit }, data) {
    return axios.get(`Attachment/list`, { params: data })
  },
  async getAttachment({ commit }, data) {
    // return axios.get("Attachment/get", data)
    return axios({
      url: "Attachment/get", //your url
      method: "GET",
      params: data,
      responseType: "blob" // important
    })
  },
  async addAttachment({ commit }, data) {
    return axios.post("Attachment/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  },
  async removeAttachment({ commit }, data) {
    return axios.get("Attachment/delete/", { params: data })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
