<template>
  <v-container class="fill-height" data-public>
    <v-layout class="d-flex align-center justify-center">
      <div class="text-center">
        <h1 class="display-2 font-weight-bold mb-3">{{$t('lost')}}</h1>
        <v-btn to="/" replace color="primary">{{$t('saveMe')}}</v-btn>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>

<i18n>
{
  "en-US": {
    "lost": "You seem to be lost...",
    "saveMe": "Get me out of here!"
  },
  "zh-TW": {
    "lost": "你似乎迷路了...",
    "saveMe": "帶我離開這裡！"
  }
}
</i18n>
