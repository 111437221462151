var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(!_vm.criticalError)?_c('div',[_c('Loading',{attrs:{"ready":_vm.ready}}),(_vm.isAuthenticated && !_vm.printMode)?_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","color":"navigation","mini-variant":_vm.miniDrawer && _vm.$vuetify.breakpoint.lgAndUp,"width":"280"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('Navigation',{attrs:{"mini-drawer":_vm.miniDrawer}})],1):_vm._e(),_c('v-app-bar',_vm._g({directives:[{name:"touch",rawName:"v-touch",value:({ right: () => _vm.startTimer() }),expression:"{ right: () => startTimer() }"}],attrs:{"elevation":_vm.printMode ? 0 : 1,"app":!_vm.printMode,"clipped-left":"","color":"navigation"}},{
        mousedown: _vm.startTimer,
        mouseup: _vm.stopTimer,
        mouseleave: _vm.stopTimer
      }),[(_vm.isAuthenticated && !_vm.printMode)?_c('v-app-bar-nav-icon',{on:{"click":_vm.toggleDrawer}}):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"shrink mr-2 hidden-xs-only",attrs:{"alt":"Kingston Logo","width":"200","src":require(_vm.dark
                ? '@/assets/ktc-logo-white-hr.png'
                : '@/assets/ktc-logo-black-hr.png'),"transition":"scale-transition"}})],1),_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('v-toolbar-title',{staticStyle:{"cursor":"pointer"},attrs:{"data-public":""},on:{"click":navigate}},[(_vm.envText)?_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_vm._v(_vm._s(_vm.envText))]):_vm._e(),_vm._v(" Travel Req "),_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.domain)+" ")])],1)]}}],null,false,4217967673)})],1),_c('v-spacer'),(_vm.authState && _vm.authState.isAuthenticated)?_c('v-btn',{attrs:{"to":"/profile","fab":"","small":"","elevation":"0"}},[_c('v-avatar',{attrs:{"size":"36","data-private":""}},[(_vm.profilePicURL)?_c('img',{attrs:{"src":'data:image/jpeg;base64,' + _vm.profilePicURL}}):_c('v-icon',[_vm._v("mdi-account")])],1)],1):_c('v-btn',{attrs:{"text":""},on:{"click":_vm.login}},[_vm._v("Login")])],1),_c('v-main',[_c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'pa-0',attrs:{"cols":"12"}},[_c('v-sheet',{class:_vm.printMode ? 'elevation-0' : 'elevation-4',staticStyle:{"display":"grid"},attrs:{"min-height":"calc(100vh - 150px)","rounded":_vm.$vuetify.breakpoint.mdAndUp ? 'lg' : '0'}},[_c('v-expand-transition',{attrs:{"mode":"out-in"}},[(_vm.ready || _vm.anonymous)?_c('router-view'):_c('v-skeleton-loader',{attrs:{"type":"image, article, table"}})],1)],1)],1),_c('v-col',{staticClass:"text-center text-caption grey--text",attrs:{"cols":"12","data-public":""}},[_vm._v("©"+_vm._s(_vm.year)+" - Kingston Technology Corporation. All Rights Reserved.")])],1)],1)],1),(_vm.ready)?_c('Region'):_vm._e(),_c('Snackbar'),(_vm.ready)?_c('Sync'):_vm._e()],1):_vm._e(),_c('ErrorAlert')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }