import axios from "axios"
import statuses from "@/data/statuses"

function initialState() {
  return {
    authorizeCount: 0,
    approveCount: 0,
    processCount: 0
  }
}

const getters = {
  searchCount(state) {
    return state.approveCount + state.processCount
  }
}

const actions = {
  async addTransportationReq({ commit }, data) {
    return axios.post("Transportation/new", data)
  },
  async updateTransportationReq({ commit }, data) {
    return axios.post("Transportation/update", data)
  },
  async updateTransportationBatch({ commit }, data) {
    return axios.post("Transportation/update-batch", data)
  },
  async updateAndProcessTransportationReq({ commit }, data) {
    return axios.post("Transportation/update-and-process", data)
  },
  async getTransportationReq({ commit }, data) {
    return axios.get("Transportation", { params: data })
  },
  async getTransportationBatch({ commit }, data) {
    return axios.get("Transportation/get-batch", { params: data })
  },
  async getArchive({ commit }, data) {
    return axios.get(`Transportation/archive`, { params: data })
  },
  async searchTransportationReq({ commit }, data) {
    return axios.get("Transportation/search", { params: data })
  },
  async searchTransportationReqByManager({ commit }, data) {
    return axios.get("Transportation/authorize", { params: data })
  },
  async getAuthorizeCount({ commit }, data) {
    return axios.get("Transportation/authorize-count").then((response) => {
      commit("setAuthorizeCount", response.data)
      return response
    })
  },
  async getApproveCount({ commit }, data) {
    return axios.get("Transportation/approve-count").then((response) => {
      commit("setApproveCount", response.data)
      return response
    })
  },
  async getProcessCount({ commit }, data) {
    return axios.get("Transportation/process-count").then((response) => {
      commit("setProcessCount", response.data)
      return response
    })
  },
  async approveTransportationReq({ commit }, data) {
    return axios.post("Transportation/approve", data)
  },
  async rejectTransportationReq({ commit }, data) {
    return axios.post("Transportation/reject", data)
  },
  async gaApproveTransportationReq({ commit }, data) {
    return axios.post("Transportation/ga-approve", data)
  },
  async gaRejectTransportationReq({ commit }, data) {
    return axios.post("Transportation/ga-reject", data)
  },
  async processTransportationReq({ commit }, data) {
    return axios.post("Transportation/process", data)
  },
  async rejectProcessTransportationReq({ commit }, data) {
    return axios.post("Transportation/reject-process", data)
  },
  async managerCommentTransportationReq({ commit }, data) {
    return axios.post("Transportation/comment-manager", data)
  },
  async gaManagerCommentTransportationReq({ commit }, data) {
    return axios.post("Transportation/comment-ga-manager", data)
  },
  async gaHandlerCommentTransportationReq({ commit }, data) {
    return axios.post("Transportation/comment-ga", data)
  }
}

const mutations = {
  setAuthorizeCount(state, count) {
    state.authorizeCount = count
  },
  setApproveCount(state, count) {
    state.approveCount = count
  },
  setProcessCount(state, count) {
    state.processCount = count
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
