<template>
  <v-row
    v-if="
      has([permissions.generalAffairsHandler]) &&
      ((items.length > 0 && loaded) || (count > 0 && !loaded))
    "
  >
    <v-col>
      <h3>
        {{ $t("welcome.transportationReqAwaitingProcess") }}
        <v-chip x-small color="primary">
          {{ count }}
        </v-chip>
      </h3>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="items.length === 0"
        mobile-breakpoint="800"
        hide-default-footer
        @click:row="view"
        class="row-pointer"
      >
      <template v-slot:item.travelDate="{ item }">
            {{ item.travelDate | dateFormat }}
          </template>
          <template v-slot:item.transportTypeId="{ item }">
            <span v-if="item.transportTypeId === transportationType.shuttle">{{
              $t("transportation.shuttle.shuttle")
            }}</span>
            <span v-if="item.transportTypeId === transportationType.taxi">{{
              $t("transportation.taxi.taxi")
            }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <StatusChip
              :statusId="item.statusId"
              :status="item.status"
            ></StatusChip>
          </template>
          <template v-slot:item.createdDate="{ item }">
            {{ item.createdDate | formatDate }}
          </template>
      </v-data-table>

      <div class="text-center">
        <v-btn to="/search/transportation" small>{{ $t("welcome.viewAll") }} </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import StatusChip from "@/components/StatusChip"
import { mapState } from "vuex"

export default {
  data() {
    return {
      loaded: false,
      items: [],
      headers: [
        {
          text: this.$t("transportation.table.transportNumber"),
          value: "transportationId"
        },
        {
          text: this.$t("transportation.table.name"),
          value: "travelerName"
        },
        {
          text: this.$t("transportation.table.travelDate"),
          value: "travelDate"
        },
        {
          text: this.$t("transportation.table.transportType"),
          value: "transportTypeId"
        },
        {
          text: this.$t("transportation.table.status"),
          value: "status"
        },
        {
          text: this.$t("transportation.table.createdDate"),
          value: "createdDate"
        }
      ]
    }
  },
  computed: {
    ...mapState("transportation", {
      count: "processCount"
    })
  },
  components: {
    StatusChip
  },
  methods: {
    view(transportationReq) {
      this.$router.push({
        path: `/search/transportation/${transportationReq.transportationId}`
      })
    }
  },
  async created() {
    if (this.has([this.permissions.generalAffairsHandler])) {
      await this.$store.dispatch("transportation/getProcessCount")

      if (this.count > 0) {
        this.$store
          .dispatch("transportation/searchTransportationReq", {
            pageSize: 3,
            statusId: this.statuses.generalAffairsApproved
          })
          .then((response) => {
            this.items = response.data
            this.loaded = true
          })
      }
    }
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
