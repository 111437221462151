<template>
  <div>
    <v-dialog
      v-if="errorAlert.critical"
      v-model="errorAlert.open"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      data-public
    >
      <v-card class="fill-height" rounded="0" color="red" data-public>
        <v-layout wrap align-center justify-center fill-height>
          <v-card-text class="text-center">
            <v-row>
              <v-col cols="12">
                <span class="display-1">{{ errorAlert.message }}</span>
              </v-col>
              <v-col cols="12">
                <v-btn
                  :disabled="!btnEnabled"
                  large
                  @click="errorAlert.callback()"
                >
                  <span v-if="btnTimer > 0">{{ btnTimer }}</span>
                  <span v-else>{{ errorAlert.buttonText }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-snackbar v-else v-model="errorAlert.open" :timeout="-1" top color="red" data-public>
      {{ errorAlert.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="errorAlert.open = false">
          {{$t("error.close")}}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      btnTimer: 0
    }
  },
  computed: {
    ...mapState(["errorAlert"]),
    btnEnabled() {
      return this.btnTimer <= 0
    }
  },
  watch: {
    errorAlert(val) {
      if (val.timer === undefined) val.timer = 6
      this.btnTimer = val.timer
      this.btnCountDown()
    }
  },
  methods: {
    btnCountDown() {
      this.btnTimer--
      if (this.btnTimer > 0) {
        setTimeout(() => {
          this.btnCountDown()
        }, 1000)
      }
    }
  },
  created() {}
}
</script>
