import { render, staticRenderFns } from "./TransportationReqsProcess.vue?vue&type=template&id=5010a535&scoped=true"
import script from "./TransportationReqsProcess.vue?vue&type=script&lang=js"
export * from "./TransportationReqsProcess.vue?vue&type=script&lang=js"
import style0 from "./TransportationReqsProcess.vue?vue&type=style&index=0&id=5010a535&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5010a535",
  null
  
)

export default component.exports