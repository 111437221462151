<template>
  <v-chip  :color="statusColor(statusId)" small outlined>
    <v-avatar left>
      <v-icon small v-if="statusId === statuses.submitted"
        >mdi-timer-sand</v-icon
      >
      <v-icon
        small
        v-else-if="
          statusId === statuses.managerApproved ||
          statusId === statuses.travelCoordinatorProcessed ||
          statusId === statuses.generalAffairsApproved ||
          statusId === statuses.generalAffairsProcessed
        "
        >mdi-check-circle</v-icon
      >
      <v-icon small v-else-if="statusId === statuses.rejected">mdi-cancel</v-icon>
      <v-icon small v-else-if="statusId === statuses.completed">mdi-flag-checkered</v-icon>
      <v-icon small v-else-if="statusId === statuses.archived">mdi-archive</v-icon>
    </v-avatar>
    {{ $t(`status.${statusId}`) }}
  </v-chip>
</template>

<script>
export default {
  sync: ["statusId", "status"],
  methods: {
    statusColor(statusId) {
      switch (statusId) {
        case this.statuses.submitted:
          return "cyan"
        case this.statuses.managerApproved:
          return "blue"
        case this.statuses.generalAffairsApproved:
          return "purple lighten-1"
        case this.statuses.travelCoordinatorProcessed:
        case this.statuses.generalAffairsProcessed:
        case this.statuses.completed:
          return "green"
        case this.statuses.rejected:
        case this.statuses.managerRejected:
        case this.statuses.travelCoordinatorRejected:
        case this.statuses.generalAffairsRejected:
        case this.statuses.generalAffairsHandlerRejected:
          return "red"
        default:
          return ""
      }
    }
  }
}
</script>
