<template>
  <v-container class="fill-height" data-public>
    <v-layout class="d-flex align-center justify-center">
      <div class="text-center">
        <h1 class="display-2 font-weight-bold mb-3">{{$t('loggedOut')}}</h1>
        <v-btn to="/" replace color="primary">{{$t('logIn')}}</v-btn>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>

<i18n>
{
  "en-US": {
    "loggedOut": "You are logged out.",
    "logIn": "Log In"
  }
}
</i18n>
