import Vue from "vue"
import store from "@/store"

import "./fvAccommodation"

import permissions from "@/data/permissions"
import statuses from "@/data/statuses"
import domains from "@/data/domains"
import transportationType from "@/data/transportationType"
import authorizationType from "@/data/authorizationType"

Vue.mixin({
  data() {
    return { permissions, statuses, domains, transportationType, authorizationType }
  },
  computed: {
    activeDomains() {
      return domains.filter(x => x.active).sort((a, b) => {
        if (a.domain < b.domain) return -1
        if (a.domain > b.domain) return 1
        return 0
      })
    }
  },
  methods: {
    getDomain(domain) {
      return domains.find(x => x.domain === domain)
    }
  }
})

Vue.mixin({
  methods: {
    has: (keys) => store.state.userPermissions.some((p) => keys.includes(p))
  }
})

Vue.mixin({
  beforeCreate() {
    const sync = this.$options.sync
    if (sync) {
      if (!this.$options.computed) {
        this.$options.computed = {}
      }
      const attrs = Object.keys(this.$attrs)
      sync.forEach((key) => {
        if (!attrs.includes(key)) {
          Vue.util.warn(`Missing required sync-prop: "${key}"`, this)
        }
        this.$options.computed[key] = {
          get() {
            return this.$attrs[key]
          },
          set(val) {
            this.$emit("update:" + key, val)
          }
        }
      })
    }
  }
})

Vue.mixin({
  computed: {
    isUK() {
      return this.$i18n.locale === "en-UK"
    },
    isUS() {
      return this.$i18n.locale === "en-US"
    }
  }
})

Vue.mixin({
  computed: {
    bottomPadding() {
      return getComputedStyle(document.documentElement).getPropertyValue("--sab")
    }
  }
})

Vue.mixin({
  computed: {
    envText() {
      return `${process.env.VUE_APP_ENV !== "PRD" ? process.env.VUE_APP_ENV : ""}`
    }
  }
})