import axios from "axios"
import statuses from "@/data/statuses"

function initialState() {
  return {
    authorizeCount: 0,
    processCount: 0
  }
}

const getters = {}

const actions = {
  async addTravelReq({ commit }, data) {
    return axios.post("Travel/new", data)
  },
  async updateTravelReq({ commit }, data) {
    return axios.post("Travel/update", data)
  },
  async updateAndProcessTravelReq({ commit }, data) {
    return axios.post("Travel/update-and-process", data)
  },
  async getTravelReq({ commit }, data) {
    return axios.get("Travel", { params: data })
  },
  async getArchive({ commit }, data) {
    return axios.get(`Travel/archive`, { params: data })
  },
  async searchTravelReq({ commit }, data) {
    return axios.get("Travel/search", { params: data })
  },
  async reportTravelReq({ commit }, data) {
    return axios.get("Travel/report", { params: data })
  },
  async reportTravelReqUKAir({ commit }, data) {
    return axios.get("Travel/report_uk_air", { params: data })
  },
  async reportTravelReqUKTrain({ commit }, data) {
    return axios.get("Travel/report_uk_train", { params: data })
  },
  async reportTravelReqUKCar({ commit }, data) {
    return axios.get("Travel/report_uk_car", { params: data })
  },
  async searchTravelReqByManager({ commit }, data) {
    return axios.get("Travel/authorize", { params: data })
  },
  async getAuthorizeCount({ commit }, data) {
    return axios
      .get("Travel/authorize-count")
      .then((response) => {
        commit("setAuthorizeCount", response.data)
        return response
      })
  },
  async getProcessCount({ commit }, data) {
    return axios
      .get("Travel/process-count")
      .then((response) => {
        commit("setProcessCount", response.data)
        return response
      })
  },
  async approveTravelReq({ commit }, data) {
    return axios.post("Travel/approve", data)
  },
  async rejectTravelReq({ commit }, data) {
    return axios.post("Travel/reject", data)
  },
  async processTravelReq({ commit }, data) {
    return axios.post("Travel/process", data)
  },
  async rejectProcessTravelReq({ commit }, data) {
    return axios.post("Travel/reject-process", data)
  },
  async managerCommentTravelReq({ commit }, data) {
    return axios.post("Travel/comment-manager", data)
  },
  async tcCommentTravelReq({ commit }, data) {
    return axios.post("Travel/comment-tc", data)
  },
  async apCommentTravelReq({ commit }, data) {
    return axios.post("Travel/comment-ap", data)
  }
}

const mutations = {
  setAuthorizeCount(state, count) {
    state.authorizeCount = count
  },
  setProcessCount(state, count) {
    state.processCount = count
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
