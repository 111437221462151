<template>
  <v-dialog
    v-model="loading"
    fullscreen
    hide-overlay
    transition="false"
    persistent
    data-public
  >
    <v-card class="fill-height">
      <v-layout
        class="text-center wrap align-center justify-center fill-height"
        data-public
      >
        <v-card flat>
          <v-img
            alt="Kingston Logo"
            eager
            contain
            height="40"
            :src="
              require(dark
                ? '@/assets/ktc-logo-white-hr.png'
                : '@/assets/ktc-logo-black-hr.png')
            "
            class="text-center"
          />
          <v-card-text class="d-flex flex-column">
            <span class="display-1"> Travel Req </span>
            <div>
              <v-chip
                label
                x-small
                :color="envText ? 'primary' : 'grey'"
                :outlined="!envText"
              >
                <span v-if="envText">{{ envText }}</span>
                <span class="ml-1">v{{ appVersion }}</span>
              </v-chip>
            </div>
          </v-card-text>
          <div v-if="loadingError">
            <v-card-text class="red--text text-h6">
              {{$t("loading.error")}}
            </v-card-text>
          </div>
          <div v-else>
            <v-progress-linear indeterminate class="mb-0"></v-progress-linear>
            <v-card-text class="body-1">{{
              fetchingUser ? $t("loading.loggingIn") : $t("loading.loading")
            }}</v-card-text>
          </div>
        </v-card>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"
import { mapState, mapGetters } from "vuex"

export default {
  name: "Loading",

  props: ["ready"],
  data: () => ({
    loading: true
  }),
  computed: {
    ...mapState(["fetchingUser", "loadingError", "appVersion"]),
    ...mapGetters(["dark"])
  },
  watch: {
    ready(val) {
      if (val) this.loading = false
    }
  },
  mounted() {
    if (this.ready) this.loading = false

    // allows log out page to show
    if (!this.$route.meta.requiresAuth) this.loading = false
  }
}
</script>
