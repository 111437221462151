import axios from "axios"

function initialState() {
  return {}
}

const getters = {}

const actions = {
  async getAccessRights() {
    return axios.get("Lookup/access-right")
  },
  async getCostCenters({ commit }, data) {
    return axios.get("Lookup/cost-center", { params: data })
  },
  async getTripTypes({ commit }, data) {
    return axios.get("Lookup/trip-type", { params: data })
  },
  async addTripType({ commit }, data) {
    return axios.post("Lookup/trip-type", data)
  },
  async updateTripType({ commit }, data) {
    return axios.put("Lookup/trip-type", data)
  },
  async getLocations({ commit }, data) {
    return axios.get("Lookup/location", { params: data })
  },
  async addLocation({ commit }, data) {
    return axios.post("Lookup/location", data)
  },
  async updateLocation({ commit }, data) {
    return axios.put("Lookup/location", data)
  },
  async getStatuses({ commit }, data) {
    return axios.get("Lookup/status")
  },
  async getSubledgers({ commit }, data) {
    return axios.get("Lookup/subledger")
  },
  async getLocationCostCenters({ commit }, data) {
    return axios.get("Lookup/location-cost-center")
  },
  async updateLink({ commit }, data) {
    return axios.put("Lookup/link", data)
  },
  async getLink({ commit }, data) {
    return axios.get("Lookup/link", { params: data })
  },
}

const mutations = {}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
