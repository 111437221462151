<template>
  <v-container class="fill-height" data-public>
    <v-layout class="d-flex align-center justify-center">
      <v-slide-y-transition>
        <div class="text-center" v-show="showText">
          <h1 class="display-1 font-weight-bold mb-3">{{ $t('noAuth') }}</h1>
          <h1 class="display-1 font-weight-bold mb-3">{{ $t('contactMIS') }}</h1>
        </div>
      </v-slide-y-transition>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from "vue"
import { mapState } from "vuex"

export default Vue.extend({
  name: "OktaLoginCallback",
  data: () => ({
    showText: false
  }),
  computed: {
    ...mapState(["userInfo"])
  },
  async beforeMount() {
    await this.$auth.handleLoginRedirect()
  },
  render() {
    return this.$scopedSlots.default && this.$scopedSlots.default({})
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.userInfo.email) 
        this.showText = true
      else
        this.$router.push("/")
    })
  }
})
</script>

<i18n>
  {
    "en-US": {
      "noAuth": "You are not authorized to access Travel Req",
      "contactMIS": "Please contact MIS"
    }
  }
</i18n>