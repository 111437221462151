export default [
  {
    domain: "US",
    region: "FV",
    companyCode: "1001",
    shortName: "KTC USA",
    companyName: "Kingston Technology Co.",
    siteCode: "KTCFV",
    language: "en-US",
    active: true
  },
  {
    domain: "TW",
    region: "FE",
    companyCode: "1200",
    shortName: "KTC Taiwan",
    companyName: "Kingston Technology Taiwan",
    siteCode: "KTCFE",
    language: "zh-TW",
    active: true
  },
  {
    domain: "SH",
    region: "SH",
    companyCode: "1220",
    shortName: "KTC China",
    companyName: "Kingston Technology China",
    siteCode: "KTCCN",
    language: "zh-CN",
    active: true
  },
  {
    domain: "SZ",
    region: "SZ",
    companyCode: "1221",
    shortName: "KTC Shenzhen",
    companyName: "Kingston Technology Shenzhen",
    siteCode: "KTCXZ",
    language: "zh-CN",
    active: true
  },
  {
    companyCode: "1230",
    shortName: "KTC Malaysia",
    companyName: "Kingston Technology Malaysia",
    siteCode: "KTCMY"
  },
  {
    companyCode: "1310",
    shortName: "KTC Germany",
    companyName: "Kingston Technology GmbH",
    siteCode: "KTCDE"
  },
  {
    domain: "UK",
    region: "UK",
    companyCode: "1316",
    shortName: "KTC England",
    companyName: "Kingston Technology Europ",
    siteCode: "KTCUK",
    language: "en-UK",
    active: true
  },
  {
    companyCode: "1320",
    shortName: "KTC France",
    companyName: "Kingston Technology SARL",
    siteCode: "KTCFR"
  },
  {
    domain: "IE",
    region: "IE",
    companyCode: "1344",
    shortName: "KTC Ireland",
    companyName: "Kingston Tech. Ireland",
    siteCode: "KTCIL",
    language: "en-IE",
    active: true
  },
  {
    domain: "BR",
    region: "BR",
    companyCode: "1401",
    shortName: "KTC Brazil",
    companyName: "Kingston Brazil",
    siteCode: "KTCBR",
    language: "pt-BR",
    active: true
  },
  {
    companyCode: "1501",
    shortName: "KTC Russia",
    companyName: "Kingston Russia Office",
    siteCode: "KTCRU"
  },
  {
    companyCode: "2200",
    shortName: "KSI Taiwan",
    companyName: "Kingston Solution Inc. Taiwan",
    siteCode: "KSIFE"
  },
  {
    companyCode: "4500",
    shortName: "KTC MEC",
    companyName: "Kingston Shenzhen Kaifa",
    siteCode: "KTCSZ"
  },
  {
    companyCode: "4540",
    shortName: "KTC OSE",
    companyName: "Kingston OSE",
    siteCode: "KTCOS"
  },
  {
    companyCode: "4550",
    shortName: "KTC Chunghong",
    companyName: "Kingston Chunghong",
    siteCode: "KTCCH"
  },
  {
    companyCode: "4560",
    shortName: "KTC Kanebao",
    companyName: "Kingston Kanebao",
    siteCode: "KTCKB"
  },
  {
    companyCode: "4570",
    shortName: "KTC TechServ",
    companyName: "Kingston TechServ",
    siteCode: "KTCTS"
  },
  {
    companyCode: "4580",
    shortName: "KTC PointTec",
    companyName: "Kingston PointTec",
    siteCode: "KTCPT"
  },
  {
    companyCode: "4590",
    shortName: "KTC PanRam",
    companyName: "Kingston PanRam Taipei",
    siteCode: "KTCPR"
  },
  {
    companyCode: "4610",
    shortName: "KTC Auton",
    companyName: "Kingston SubCon Auton",
    siteCode: "KTCAT"
  },
  {
    companyCode: "4620",
    shortName: "KTC PTI",
    companyName: "Kingston SubCon PTI",
    siteCode: "KTCPI"
  },
  {
    companyCode: "4630",
    shortName: "KTC CalComp Thailand",
    companyName: "Kingston SubCon CalComp Thailand",
    siteCode: "KTCCP"
  },
  {
    companyCode: "5600",
    shortName: "Payton ShenZhen",
    companyName: "Payton Shenzhen",
    siteCode: "PTISZ"
  },
  {
    companyCode: "5610",
    shortName: "PTI Taiwan",
    companyName: "Powertech Technology Inc.",
    siteCode: "PTITW"
  },
  {
    companyCode: "5700",
    shortName: "JMSH",
    companyName: "JMSH",
    siteCode: "JMSH"
  },
  {
    companyCode: "5710",
    shortName: "Keyou Tech ShenZhen (OSC)",
    companyName: "Keyou Tech SheZhen (OCS)",
    siteCode: "KEYOU"
  },
  {
    companyCode: "6001",
    shortName: "KTC Mexico",
    companyName: "Kingston Mexico",
    siteCode: "KTCMX"
  },
  {
    domain: "IN",
    region: "IN",
    companyCode: "6200",
    shortName: "KTC India",
    companyName: "Kingston Technology Taiwan",
    siteCode: "KTCIN",
    language: "hi-IN",
    active: true
  },
  {
    companyCode: "7100",
    shortName: "SubCon Takstar",
    companyName: "Guangdong Takstar Electronic Co.",
    siteCode: "SUBTK"
  },
  {
    companyCode: "7120",
    shortName: "SubCon Foreshot",
    companyName: "Foreshot Industrial Corporation",
    siteCode: "SUBFS"
  },
  {
    companyCode: "7130",
    shortName: "SubCon SZ YanTian Hub",
    companyName: "SZ YanTian Hub",
    siteCode: "SUBYT"
  }
]
