<template>
  <div>
    <v-img
      alt="Kingston Logo"
      :src="
        require(dark
          ? '@/assets/ktc-logo-white-hr.png'
          : '@/assets/ktc-logo-black-hr.png')
      "
      class="ma-3 mb-2 d-lg-none"
      contain
    />
    <v-list color="transparent" v-if="ready">
      <template v-for="(item, index) in links">
        <v-divider
          v-if="
            item.divider && (item.permissions == null || has(item.permissions))
          "
          class="my-2"
          :key="index"
        ></v-divider>

        <v-list-item
          v-else-if="item.permissions == null || has(item.permissions)"
          :to="item.url"
          link
          :key="index"
          data-public
        >
          <v-list-item-icon
            @click="item.tooltip = false"
            v-touch="{ end: () => closeTooltip(item) }"
          >
            <v-badge
              overlap
              color="primary"
              :content="getBadge(item.name)"
              :value="miniDrawer ? getBadge(item.name) : 0"
            >
              <v-tooltip right v-model="item.tooltip" :disabled="!miniDrawer">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> {{ item.icon }} </v-icon>
                </template>
                {{ $t("routes." + item.name) }}
              </v-tooltip>
            </v-badge>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("routes." + item.name) }}
              <v-chip x-small color="primary" v-if="getBadge(item.name)">
                {{ getBadge(item.name) }}
              </v-chip>
              <!-- <v-badge
                inline
                color="primary"
                :content="getBadge(item.name)"
                :value="getBadge(item.name)"
              >
                {{ $t("routes." + item.name) }}
              </v-badge> -->
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-list v-else>
      <v-skeleton-loader
        type="list-item, list-item, list-item, list-item, divider, list-item, list-item, divider, list-item, list-item"
      ></v-skeleton-loader>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import permissions from "@/data/permissions"
export default {
  props: ["miniDrawer"],
  data: () => ({
    links: [
      {
        icon: "mdi-home",
        name: "home",
        url: "/",
        tooltip: false
      },
      {
        icon: "mdi-airplane",
        name: "new",
        url: "/new",
        tooltip: false
      },
      {
        icon: "mdi-taxi",
        name: "new-transportation",
        url: "/new-transportation",
        tooltip: false
      },
      {
        icon: "mdi-bed",
        name: "new-fv-accommodation",
        url: "/new-fv-accommodation",
        tooltip: false
      },
      {
        icon: "mdi-archive",
        name: "archive",
        url: "/archive",
        tooltip: false
      },
      {
        divider: true
      },
      {
        icon: "mdi-signature",
        name: "authorize",
        url: "/authorize",
        tooltip: false,
        permissions: [permissions.admin, permissions.manager]
      },
      {
        icon: "mdi-archive-search",
        name: "search",
        url: "/search",
        tooltip: false
        // permissions: [
        //   permissions.admin,
        //   permissions.manager,
        //   permissions.travelCoordinator,
        //   permissions.accountsPayable,
        //   permissions.generalAffairsManager,
        //   permissions.generalAffairsHandler,
        //   permissions.mis
        // ]
      },
      {
        divider: true,
        permissions: [permissions.admin, permissions.accountsPayable, permissions.ukReport]
      },
      {
        icon: "mdi-file-table",
        name: "report",
        url: "/report",
        tooltip: false,
        permissions: [permissions.admin, permissions.accountsPayable, permissions.ukReport]
      },
      {
        divider: true,
        permissions: [permissions.admin]
      },
      {
        icon: "mdi-database",
        name: "manage",
        url: "/manage",
        tooltip: false,
        permissions: [permissions.admin]
      },
      {
        icon: "mdi-security",
        name: "admin",
        url: "/admin",
        tooltip: false,
        permissions: [permissions.admin]
      },
      {
        divider: true
      },
      {
        icon: "mdi-cog",
        name: "settings",
        url: "/settings",
        tooltip: false
      },
      {
        icon: "mdi-information",
        name: "info",
        url: "/info",
        tooltip: false
      }
    ]
  }),
  computed: {
    ...mapGetters(["ready", "dark", "authorizeCount", "searchCount"])
  },
  methods: {
    getBadge(name) {
      if (name === "authorize" && this.authorizeCount)
        return this.authorizeCount
      if (name === "search" && this.searchCount) return this.searchCount
      return 0
    },
    closeTooltip(item) {
      setTimeout(() => {
        item.tooltip = false
      }, 100)
    }
  }
}
</script>
