<template>
  <v-row
    v-if="
      has([permissions.generalAffairsManager]) &&
      ((items.length > 0 && loaded) || (count > 0 && !loaded))
    "
  >
    <v-col>
      <h3>
        {{ $t("welcome.fvAccommodationReqAwaitingGAApproval") }}
        <v-chip x-small color="primary">
          {{ count }}
        </v-chip>
      </h3>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="items.length === 0"
        mobile-breakpoint="800"
        hide-default-footer
        @click:row="view"
        class="row-pointer"
      >
      <template v-slot:item.status="{ item }">
          <StatusChip
            :statusId="item.statusId"
            :status="item.status"
          ></StatusChip>
        </template>
        <template v-slot:item.authorizeManagerFullName="{ item }">
          {{ item.authorizeManagerFullName | redacted }}
        </template>
        <template v-slot:item.createdDate="{ item }">
          {{ item.createdDate | formatDate }}
        </template>
      </v-data-table>

      <div class="text-center">
        <v-btn to="/search/fv-accommodation" small
          >{{ $t("welcome.viewAll") }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import StatusChip from "@/components/StatusChip"
import { mapState } from "vuex"

export default {
  data() {
    return {
      loaded: false,
      items: [],
      headers: [
      {
          text: this.$t("fvAccommodation.table.fvAccommodationId"),
          value: "fvAccommodationId"
        },
        {
          text: this.$t("fvAccommodation.table.submitter"),
          value: "createdByFullName"
        },
        {
          text: this.$t("fvAccommodation.table.status"),
          value: "status"
        },
        {
          text: this.$t("fvAccommodation.table.manager"),
          value: "authorizeManagerFullName"
        },
        {
          text: this.$t("fvAccommodation.table.createdDate"),
          value: "createdDate"
        }
      ]
    }
  },
  computed: {
    ...mapState("fvAccommodation", {
      count: "approveCount"
    })
  },
  components: {
    StatusChip
  },
  methods: {
    view(req) {
      this.$router.push({
        path: `/search/fv-accommodation/${req.fvAccommodationId}`
      })
    }
  },
  async created() {
    if (this.has([this.permissions.generalAffairsManager])) {
      await this.$store.dispatch("fvAccommodation/getApproveCount")

      if (this.count > 0) {
        this.$store
          .dispatch("fvAccommodation/searchFVAccommodationReq", {
            pageSize: 3,
            statusId: this.statuses.managerApproved
          })
          .then((response) => {
            this.items = response.data
            this.loaded = true
            // this.$store
            //   .dispatch("fvAccommodation/searchFVAccommodationReq", {
            //     pageSize: 3,
            //     statusId: this.statuses.submitted,
            //     isBusinessPayment: false
            //   })
            //   .then((response2) => {
            //     this.items = [...response.data, ...response2.data]
            //     this.loaded = true
            //   })
          })
      }
    }
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
