import axios from "axios"

function initialState() {
  return {}
}

const getters = {}

const actions = {
  async getEmployees({commit}, data) {
    return axios.get(`User/employee`, { params: data })
  },
  async addEmployee({commit}, data) {
    return axios.post("User/employee", data)
  },
  async updateEmployee({commit}, data) {
    return axios.put("User/employee", data)
  },
  async fetchEmployee({commit}, data) {
    return axios.get("User/fetch-id", { params: data })
  },
  async searchEmployee({commit}, data) {
    return axios.post("User/search", data)
  },
  async getManagers({commit}, data) {
    return axios.get("User/managers", {params: data})
  },
  async syncManagers({commit}, data) {
    return axios.post("User/sync-managers")
  }
}

const mutations = {}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
